
import React, {useState, useRef, useEffect} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import Form from 'react-bootstrap/Form'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {toast} from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';

const PopupFormReferrals = (props) => {
    const [verified, setVarified] = useState(false)
    function onChange(value) {
      console.log("Captcha value:", value);
      setVarified(true);
    }
    const refform = useRef();
    const sendForm = (e) =>{
      e.preventDefault();
      emailjs.sendForm('service_gmail01','template_northshore2',e.target,'2-EU-Dmtl9lxLGg_r').then(res=>{
        console.log(res)
        window.location.href="/thank-you"
    }).catch(err=>{
      console.log(err)
    });
    e.target.reset()
  };

  
    const [full_name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [designation, setDesignation] = useState("");
    const [address, setAddress] = useState("");
    const [work_experience, setWorkExperience] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        // btn-submit add spinner
        const btn = document.getElementById("btn-submit");
        btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...`;
        btn.disabled = true;
        await sendMessage({
            full_name,
            email,
            phone,
            designation,
            address,
            work_experience,
            message
        });
    }
    
    async function sendMessage(credentials) {
        await axios.post(`${process.env.REACT_APP_API_URL}/enduser/contact`, credentials, {"Contant-Type": "application/json"})
            .then((response) => {
                if(response.status === 200){
                    toast.success(response.data.message);
                    // reset form   
                    setName("");
                    setEmail("");
                    setPhone("");
                    setMessage("");
                }else if(response.status === 401){
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                    // console.log(error.response.data.message);
                }
            })
            .finally(() => {
                // btn-submit remove spinner
                const btn = document.getElementById("btn-submit");
                btn.innerHTML = `SUBMIT`;
                btn.disabled = false;
            });
            
    }
    return (
        <>
<div className="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel"> Join Our Referral Program </h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <form className='form-np' onSubmit={handleSubmit}>
                                        <div className='form-group mb-3'>
                                            <input type='text' className='form-control rounded-0 py-2' placeholder='Full Name *' onChange={e=>setName(e.target.value) } required value={full_name}/>
                                        </div>
                                        <div className='form-group mb-3'>
                                            <input type='email' className='form-control rounded-0 py-2' placeholder='Email *' required onChange={(e=>setEmail(e.target.value))} value={email}/>                                            
                                        </div>
                                        <div className='form-group mb-3'>
                                        <PhoneInput
                                                placeholder="Phone *"
                                                id="phone"
                                                countryCallingCodeEditable={false}
                                                international
                                                defaultCountry='CA'
                                                value={phone}
                                                className="form-control rounded-0 py-2"
                                                onChange={setPhone}
                                            />
                                        </div>
                                        {/* <div className='form-group mb-3 d-flex'>
                                            <input type='text' className='w-50 form-control rounded-0 py-2' placeholder='Designation *' onChange={e=>setDesignation(e.target.value) } required value={designation}/>
                                            <div className='pe-2'/>
                                            <input type='text' className='w-50 form-control rounded-0 py-2' placeholder='Address *' onChange={e=>setAddress(e.target.value) } required value={address}/>
                                        </div>
                                        <div className='form-group mb-3'>
                                        <select className="form-select rounded-0 py-2" aria-label="Default select example" name="work_experience" onChange={e=>setWorkExperience(e.target.value)} required value={work_experience}>
                                            <option value="Work Experience"> Work Experience * </option>
                                            <option value="<1"> Less than 1 year </option>
                                            <option value="1-3"> 1-3 years </option>
                                            <option value="3-5"> 3-5 years </option>
                                            <option value="5+"> 5+ years </option> 
                                            </select>                                         
                                        </div> */}
                                        
                                        <div className='form-group mb-3'>
                                            <Form.Control
                                                className='rounded-0'
                                                as="textarea"
                                                placeholder="Leave a message here"
                                                style={{ height: '100px' }} onChange={e=>setMessage(e.target.value)}
                                                value={message}/>
                                        </div>

                                        {/* <div className='form-group mb-3'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                            <span> By submitting this form I agree to <a href="/terms-and-conditions" className="text-decoration-none text-zk-primary"> Terms of Use  </a> </span>
                                        </div>
                                        </div> */}
                                        <div className="">
                                            <ReCAPTCHA
                                            // sitekey="6LfZjx4nAAAAAMnyR_l8ISMXnPJtao1SC1C2joyh"
                                            // For Local Host
                                            sitekey="6LeBksYpAAAAAEARKOI3Xjdb5_aJvbxU5ewKK0EM" 
                                            onChange={onChange}/>
                                            <button className='mt-3 w-100 btn-block bg-zk-primary rounded-0 text-white btn btn-zk-primary' id="btn-submit"> SUBMIT </button>
                                            </div>

                                        <div className=''>

                                        </div>
                                    </form>        
      </div>
    </div>
  </div>
</div>
        </>
    )
}

export default PopupFormReferrals